import styled, { createGlobalStyle, css } from "styled-components";

export const mediaBpXSmall_second = "max-width: 340px";

export default createGlobalStyle(({ theme: { colors } }) => {
  return css`
    * {
      box-sizing: border-box;
    }

    html,
    body {
      margin: 0;
      padding: 0;
      -ms-overflow-style: none;
      height: 100%;
      width: 100%;
      -webkit-text-size-adjust: 100%;
      user-select: none;
      -webkit-tap-highlight-color: transparent;
      overflow: hidden;
      -webkit-touch-callout: none;
    }

    body {
      font-family: "speedee", sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: ${colors.black};
      font-size: 16px;
      line-height: 1.5;
      background: ${colors.grey};
    }

    #root {
      height: 100%;
    }

    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
    }

    h1,
    h2,
    h3 {
      font-family: "speedee_bold", sans-serif;
      line-height: 1.25;
    }

    a {
      color: ${colors.black};

      &:active,
      &:hover {
        outline: 0;
      }
    }

    img {
      max-width: 100%;
      vertical-align: top;
    }

    svg {
      vertical-align: top;
    }
  `;
});

export const AppWrapper = styled.div((props) => {
  const { appBg } = props;

  return css`
    position: relative;
    height: 100%;
    overflow: auto;
    background: ${appBg === "" ? "#fff" : appBg};
  `;
});

export const AppLayout = styled.div(({ theme }) => {
  const { media, spacings } = theme;
  return css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    padding: 0 ${spacings.base};
    margin: 0 auto;
    max-width: ${media.xs};
  `;
});
