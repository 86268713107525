import React, {
  useEffect,
  useState,
  useCallback,
  useLayoutEffect,
  useRef,
} from "react";

import Tile from "components/Tile";
import Words from "components/Words";

import { saveDataToSession, getDataFromSession } from "../helpers";
import { getCards } from "services/api.service";

import { content } from "./content";

import { TilesContainer } from "components/Tile/Tile.styles";
import { HappyMealSection, LandingContainer } from "./Landing.styles";

function Landing() {
  const [cardsList, setCardsList] = useState([]);
  const [isVisibleList, setVisibleList] = useState(false);
  const landingRef = useRef(null);
  const cardsSize = useRef(0);

  const getCardsHandler = async () => {
    try {
      const cardsList = await getCards();
      setCardsList(cardsList);
    } catch (error) {
      console.error(error);
    }
  };

  const checkImgLoadedCallback = useCallback(() => {
    cardsSize.current = cardsSize.current + 1;

    if (cardsSize.current === cardsList.length) {
      setVisibleList(true);
    }
  }, [cardsList]);

  useLayoutEffect(() => {
    const sessionKey = "mcd-family_page-rendered";
    const pageVisitedClass = sessionKey;
    const pageVisited = getDataFromSession(sessionKey);
    const landingNode = landingRef.current;
    if (pageVisited) {
      landingNode.classList.add(pageVisitedClass);
    } else {
      saveDataToSession("mcd-family_page-rendered", true);
    }
    return () => {
      landingNode.classList.remove(pageVisitedClass);
    };
  }, []);

  useEffect(() => {
    getCardsHandler();
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: "page_view",
      eventProperties: {
        page_virtual_path: "/family",
        page_type: "family",
      },
    });
  }, []);

  return (
    <LandingContainer ref={landingRef}>
      <Words />
      <p className="subheader-content">{content.header.subHeader}</p>
      <TilesContainer>
        {cardsList.map(
          ({ title, backgroundColor, logoUrl, subtitle, url, openOutside }) => (
            <Tile
              key={title}
              title={title}
              url={url}
              subtitle={subtitle}
              logoUrl={logoUrl}
              backgroundColor={backgroundColor}
              openOutside={openOutside}
              isVisible={isVisibleList}
              handleImgLoaded={checkImgLoadedCallback}
            />
          )
        )}
      </TilesContainer>
      <HappyMealSection />
    </LandingContainer>
  );
}

export default Landing;
