import styled, { css, keyframes } from "styled-components";

import { HappyMeal } from "assets/images";

export const LandingContainer = styled.div(({ theme: { typography } }) => {
  return css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    margin-bottom: auto;

    .subheader-content {
      font-size: ${typography.t_14};
      line-height: ${typography.t_16};
      margin: 0;
      padding: 0;
      text-align: center;
    }
  `;
});

export const HappyMealSection = styled.div(() => {
  return css`
    position: absolute;
    width: 200px;
    overflow: hidden;
    animation: ${happyMeal} 10s ease-in forwards;
    background: url(${HappyMeal}) no-repeat bottom center;
    background-size: 80px;
    bottom: 0;
    height: 150px;
    transition: bottom 1s linear;

    /* ${LandingContainer}.mcd-family_page-rendered & {
      animation: none;
      bottom: 0;
    } */
  `;
});

export const happyMeal = keyframes`
  0%, 2% {
    opacity: 0;
    transform: translateY(-36.45vh) scale(1.5);
  }

  2%, 5% {
    opacity: 1;
    transform: translateY(-40.09vh) scale(1.5);
  }

  15%{
    opacity: 1;
    transform: translateY(0) scale(1);
  }

  16%{
    opacity: 1;
    transform: translateY(-1.21vh) scale(1);
  }

  18%{
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`;
