import styled, { css, keyframes } from "styled-components";

import { LandingContainer } from "routes/Landing/Landing.styles";

import { mediaBpXSmall_second } from "theme/globalStyles";
import { media } from "theme/theme";

const animTime = 5;

const itemDefault = keyframes`
  0%, 40% {
    opacity: 0;
  }

  45%, 100% {
    opacity: 1;
  }
`;

const contentAnimation = keyframes`
  0%, 35% {
    opacity: 0;
    visibility: hidden;
  }

  40%, 100% {
    opacity: 1;
    visibility: visible;
  }
`;

const createTileCss = (tileAnim, tileBlockAnim, tileImgAnim, tileImgAnimXS) => {
  return css`
    animation: ${animTime}s ease-in 0s forwards ${tileAnim};

    .img-container {
      animation: ${animTime}s ease-in 0s forwards ${tileBlockAnim};
    }

    img {
      animation: ${animTime}s ease-in 0s forwards ${tileImgAnim};

      @media (${mediaBpXSmall_second}) {
        animation: ${animTime}s ease-in 0s forwards ${tileImgAnimXS};
      }
    }
  `;
};

const bgAnimCss = (XSmallFlag = false) => keyframes`
  0%, 20% {
    opacity: 1;
    border-radius: 50%;
    width: 9vh;
    height: 9vh;
  }

  30% {
    opacity: 1;
    border-radius: 50%;
    width: 18vh;
    height: 18vh;
  }

  40%, 100% {
    border-radius: 18px;
    width: 100%;
    height: auto;
    padding: ${XSmallFlag ? " 65px 0 18px" : " 80px 0 18px"};
  }
`;

const imgAnimCss = (initVal, middleVal, finalVal) => keyframes`
  0%, 20% {
    width: ${initVal}px;
  }

  30% {
    width: ${middleVal}px;
  }

  40%, 100% {
    width: ${finalVal}px;
  }
`;

const tileAnimCss = (arr1, arr2) => keyframes`
  0%, 20% {
    opacity: 0;
    transform: translate(${arr1[0]}vh, ${arr1[1]}vh);
  }

  30% {
    opacity: 1;
    transform: translate(${arr2[0]}vh, ${arr2[1]}vh);
  }

  40%, 100% {
    opacity: 1;
    transform: translate(0,0);
  }
`;

const imgWrapAnimCss = (arr1, arr2) => keyframes`
  0%, 20% {
    opacity: 1;
    transform: translate(${arr1[0]}%, ${arr1[1]}px) rotate(${arr1[2]}deg);
  }

  30% {
    opacity: 1;
    transform: translate(${arr2[0]}%, ${arr2[1]}px) rotate(${arr2[2]}deg);
  }

  40%, 100% {
    opacity: 1;
    transform: translate(0%, -45px) rotate(0deg);
  }
`;

export const TilesContainer = styled.ul(() => {
  return css`
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    max-width: ${media.xs};
    width: 100%;
    margin: 0 auto 120px;
    z-index: 1;
  `;
});

export const TileWrapper = styled.li(
  ({ backgroundColor, theme: { colors }, isVisible }) => {
    return css`
      display: ${isVisible ? "block" : "none"};
      color: ${colors.white};
      text-decoration: none;
      text-align: center;
      position: relative;
      width: 47%;
      margin-top: 60px;
      animation: ${animTime}s ease-in 0s forwards ${itemDefault};

      .img-container {
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        animation: ${animTime}s ease-in 0s forwards
          ${imgWrapAnimCss([0, 20, -5], [0, -20, -5])};
      }

      img {
        max-width: 105px;
        max-height: 110px;
        object-fit: contain;
        animation: ${animTime}s ease-in 0s forwards ${imgAnimCss(80, 80, 100)};

        @media (${mediaBpXSmall_second}) {
          max-height: 95px;
        }
      }

      ${TileStyled} {
        background-color: ${backgroundColor};
        animation: ${animTime}s ease-in 0s forwards ${bgAnimCss()};

        @media (${mediaBpXSmall_second}) {
          animation: ${animTime}s ease-in 0s forwards ${bgAnimCss(true)};
        }
      }

      &:nth-of-type(1) {
        ${createTileCss(
          tileAnimCss([12, 54], [1, -4]),
          imgWrapAnimCss([0, 20, -5], [0, -20, -5]),
          imgAnimCss(80, 80, 125),
          imgAnimCss(65, 65, 125)
        )}
      }

      &:nth-of-type(2) {
        ${createTileCss(
          tileAnimCss([-12, 54], [2, 4]),
          imgWrapAnimCss([0, 25, 25], [0, 25, 25]),
          imgAnimCss(70, 70, 110),
          imgAnimCss(60, 60, 110)
        )}
      }

      &:nth-of-type(3) {
        ${createTileCss(
          tileAnimCss([12, 30], [8, -4]),
          imgWrapAnimCss([-10, 0, -60], [-10, -20, -60]),
          imgAnimCss(55, 55, 95),
          imgAnimCss(45, 45, 95)
        )}
      }

      &:nth-of-type(4) {
        ${createTileCss(
          tileAnimCss([-12, 30], [3, 10]),
          imgWrapAnimCss([10, -5, 30], [10, -5, 30]),
          imgAnimCss(50, 50, 105),
          imgAnimCss(40, 40, 105)
        )}
      }

      ${LandingContainer}.mcd-family_page-rendered & {
        animation: none;
        opacity: 1;
        transform: translate(0, 0);

        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4) {
          animation: none;
          opacity: 1;
          transform: translate(0, 0);
        }

        .img-container {
          animation: none;
          opacity: 1;
          transform: translate(0%, -45px) rotate(0deg);
        }

        img {
          animation: none;
          width: 100px;
        }

        ${TileStyled} {
          animation: none;
          border-radius: 18px;
          width: 100%;
          height: auto;
          padding: 80px 0 18px;

          @media (${mediaBpXSmall_second}) {
            animation: none;
            padding: 65px 0 18px;
          }
        }
      }
    `;
  }
);

export const TileContent = styled.div(({ theme: { typography } }) => {
  return css`
    text-align: center;
    max-width: 90%;
    width: 100%;
    margin: 0 auto;
    visibility: hidden;
    animation: ${animTime - 2}s ease-in 1s forwards ${contentAnimation};

    h3 {
      font-size: ${typography.t_18};
      word-break: break-all;
      margin: 0 0 5px 0;

      @media (${mediaBpXSmall_second}) {
        font-size: ${typography.t_16};
      }
    }

    p {
      font-size: ${typography.t_14};
      line-height: ${typography.t_16};
      margin: 5px 0 0 0;
      min-height: 35px;

      @media (${mediaBpXSmall_second}) {
        font-size: ${typography.t_13};
      }
    }

    ${LandingContainer}.mcd-family_page-rendered & {
      animation: none;
      opacity: 1;
      visibility: visible;
    }
  `;
});

export const TileStyled = styled.a(({ backgroundColor, theme: { colors } }) => {
  return css`
    color: ${colors.white};
    text-decoration: none;
    background-color: ${backgroundColor};
    box-shadow: 0 10px 13px -7px ${colors.greySecond},
      5px 5px 15px 5px rgba(0, 0, 0, 0);
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    margin: 0 auto;
  `;
});

export const TileTitle = styled.h3(
  ({ theme: { typography } }) => css`
    font-size: ${typography.t_17};
    margin: 10px 0;
  `
);
