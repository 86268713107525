// color palette
const primary = {
  white: "#fff",
  grey: "#F8F8F8",
  greySecond: "#777",
  black: "#000",
  yellow: "#fcb32b",
  red: "#d2191a",
  redSecond: "#e37173",
  blue: "#4e93c2",
  blueSecond: "#9dcddf",
  green: "#829789",
};

export const spacings = {
  base: "1rem", // 16px
};

export const typography = {
  t_20: "1.25rem", // 20px
  t_18: "1.125rem", // 18px
  t_17: "1.0625rem", // 17px
  t_16: "1rem", //16px
  t_14: "0.875rem", // 14px
  t_13: "0.8125rem", // 13px
  t_12: "0.75rem", // 12px
  t_10: "0.625rem", // 10px
};

export const media = {
  xs: "375px",
};

export const colors = {
  ...primary,
};
