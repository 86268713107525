import { db } from "lib/firebase";

export async function getCards() {
  const env = process.env.REACT_APP_ENV;

  try {
    const snapshot = await db
      .collection("fl_content")
      .orderBy("order")
      .where("_fl_meta_.schema", "==", "cards")
      .where("_fl_meta_.env", "==", env)
      .get();

    const cardsList = snapshot.docs.map((doc) => {
      const { title, subtitle, logoUrl, url, backgroundColor, openOutside } =
        doc.data();
      return {
        title,
        subtitle,
        logoUrl,
        url,
        backgroundColor,
        openOutside,
      };
    });

    return cardsList;
  } catch (error) {
    throw new Error(error);
  }
}

export async function getBg() {
  const env = process.env.REACT_APP_ENV;

  try {
    const snapshot = await db
      .collection("fl_content")
      .orderBy("order")
      .where("_fl_meta_.schema", "==", "backgroundColor")
      .where("_fl_meta_.env", "==", env)
      .get();

    return snapshot.docs[0]?.data().backgroundColor;
  } catch (error) {
    throw new Error(error);
  }
}
