import React, { useRef, useEffect } from "react";

import lottieService from "services/lottie.service";

const Loader = () => {
  const lottieLoaderRef = useRef(null);

  useEffect(() => {
    lottieService({
      container: lottieLoaderRef.current,
      animationData: require("lottie/loader1.json"),
    });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        fontSize: "1.25rem",
        justifyContent: "center",
        alignItems: "center",
        color: "#000",
      }}
      ref={lottieLoaderRef}
    />
  );
};

export default Loader;
