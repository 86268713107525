import React from "react";

import { WordsSection, Icon } from "./Words.styles";

import {
  ALetter,
  FLetter,
  ILetter,
  LLetter,
  MLetter,
  YLetter,
  YLetterXs,
  MLetterXs,
} from "assets/images";

function Words() {
  return (
    <WordsSection>
      <div className="words__inner-container">
        <div className="words-row__first">
          <Icon src={MLetterXs} />
          <Icon src={YLetterXs} />
        </div>
        <div className="words-row__second">
          <Icon src={FLetter} />
          <Icon src={ALetter} />
          <Icon src={MLetter} />
          <Icon src={ILetter} />
          <Icon src={LLetter} />
          <Icon src={YLetter} />
        </div>
      </div>
    </WordsSection>
  );
}

export default React.memo(Words);
