import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";

import { TileWrapper, TileContent, TileStyled, TileTitle } from "./Tile.styles";

const getEventData = (title) => {
  let data = {};

  switch (title) {
    case "Speeltjes":
      data = {
        action: "Nu in het Happy Meal: Teenie Beenie Boo",
        label: "/family/speeltjes",
      };
      break;

    case "Kinderfonds":
      data = {
        action: "Je ouders altijd dichtbij",
        label: "/family/kinderfonds",
      };
      break;

    case "Luister verhalen":
      data = {
        action: "Avontuurlijke verhalen voor jong en oud",
        label: "/family/luister-verhalen",
      };
      break;

    case "Speelplaats":
      data = {
        action: "Vind een restaurant met speelplaats",
        label: "/family/speelplaats",
      };
      break;

    case "Voedingswaarde":
      data = {
        action: "Weet wat je eet",
        label: "/family/voedingswaarde",
      };
      break;

    default:
      data = {
        action: "Veel plezier met de Kleine Detectives",
        label: "/family/boekjes",
      };
      break;
  }

  return data;
};

function Tile({
  backgroundColor,
  title,
  subtitle,
  logoUrl,
  url,
  isVisible,
  openOutside,
  handleImgLoaded,
}) {
  const tileRef = useRef(null);

  const tileUrl = openOutside ? `${url}?openOutsideMcd=true` : url;

  const sendGtmData = () => {
    const data = getEventData(title);
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: "button_click",
      eventAction: subtitle,
      eventLabel: data.label,
    });
  };

  const handleLoad = useCallback(() => {
    handleImgLoaded();
  }, [handleImgLoaded]);

  return (
    <TileWrapper isVisible={isVisible} backgroundColor={backgroundColor}>
      <TileStyled ref={tileRef} href={tileUrl} onClick={sendGtmData}>
        <div className="img-container">
          <img src={logoUrl} onLoad={handleLoad} alt={title} />
        </div>
        <TileContent>
          <TileTitle>{title}</TileTitle>
          <p>{subtitle}</p>
        </TileContent>
      </TileStyled>
    </TileWrapper>
  );
}

Tile.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  logoUrl: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  openOutside: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  handleImgLoaded: PropTypes.func.isRequired,
};

export default Tile;
