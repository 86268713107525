import styled, { css, keyframes } from "styled-components";

import { LandingContainer } from "routes/Landing/Landing.styles";

const animTime = 1;

export const Icon = styled.img`
  height: 100%;
  width: 100%;
`;

export const WordsSection = styled.div(({ theme }) => {
  const { media } = theme;

  const animDelay = 0;
  return css`
    margin-bottom: auto;
    width: 100%;
    text-align: center;
    line-height: 1;
    opacity: 0;
    padding: 15px 0;
    animation: ${animTime}s ease-in ${animDelay}s forwards ${letterOpacity};

    .words__inner-container {
      max-width: ${media.xs};
      margin: 0 auto;
    }

    .words-row__first {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        animation: ${animTime}s ease-in ${animDelay}s forwards ${letterOpacity};
        max-width: 30px;
        opacity: 0;

        &:nth-of-type(2) {
          max-width: 20px;
          transform: translate(-4px, 0);
          animation-delay: ${animDelay + 0.1}s;
        }
      }
    }

    .words-row__second {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -5px;

      img {
        opacity: 0;
        max-width: 30px;
        animation: ${animTime}s ease-in ${animDelay + 0.3}s forwards
          ${letterOpacity};

        &:nth-of-type(1) {
          max-width: 25px;
          transform: translate(12px, 0);
        }

        &:nth-of-type(2) {
          max-width: 25px;
          transform: translate(10px, -2px);
          animation-delay: ${animDelay + 0.4}s;
        }
        &:nth-of-type(3) {
          max-width: 38px;
          transform: translate(9px, 5px);
          animation-delay: ${animDelay + 0.5}s;
        }
        &:nth-of-type(4) {
          max-height: 37px;
          width: 14px;
          transform: translate(7px, -4px);
          animation-delay: ${animDelay + 0.6}s;
        }
        &:nth-of-type(5) {
          max-height: 33px;
          width: 16px;
          transform: translate(5px, 3px);
          animation-delay: ${animDelay + 0.7}s;
        }
        &:nth-of-type(6) {
          max-width: 33px;
          transform: translate(0, 9px);
          animation-delay: ${animDelay + 0.8}s;
        }
      }
    }

    ${LandingContainer}.mcd-family_page-rendered & {
      animation: none;
      opacity: 1;

      .words-row__first,
      .words-row__second {
        img {
          animation: none;
          opacity: 1;
        }
      }
    }
  `;
});

export const letterOpacity = keyframes`
  0% {
    opacity: 0;
  }

  19%, 100% {
    opacity: 1;
  }
`;
