import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import { initFirebase } from "lib/firebase";
import { useLoader, useFonts } from "./hooks";

import Landing from "routes/Landing";

import { getBg } from "services/api.service";

import { AppLayout, AppWrapper } from "theme/globalStyles";
// import * as images from "assets/images";

function App() {
  const [isDBInited, setIsDBInited] = useState(true);
  const [appBg, setAppBg] = useState("");
  const isFontsLoading = useFonts();
  // const isImagesLoaded = useImages(images);
  const loader = useLoader(!isFontsLoading || isDBInited);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const loggedIn = firebase.auth().currentUser;
        if (loggedIn) {
          const currentBg = await getBg();
          if (currentBg) {
            setAppBg(currentBg);
          }
          setIsDBInited(false);
        }
      } else {
        initFirebase();
      }
    });
  }, []);

  return (
    <AppWrapper appBg={appBg}>
      <AppLayout>{loader || <Landing />}</AppLayout>
    </AppWrapper>
  );
}

export default App;
